import React, { useCallback, useRef } from "react"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import { Link } from "gatsby"
import { Col, Container, Row } from "react-bootstrap"
import { Helmet } from "react-helmet"

import * as styles from "./misi.module.css"
import catImage from "../assets/cat1.svg"
import ReactTooltip from "react-tooltip"
import CheckoutHistoryContent from "./CheckoutHistoryContent"

// markup
const CheckoutHistory = ({ }: any) => {
  const ref = useRef<HTMLDivElement>(null)

  const [canvasVisibility, setCanvasVisibility] = React.useState("none")

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Riwayat Pembelian</title>
      </Helmet>

      <CheckoutHistoryContent />
    </>
  )
}

export default CheckoutHistory
